import { render, staticRenderFns } from "./TourStages1x1.vue?vue&type=template&id=33c327cc&scoped=true&"
import script from "./TourStages1x1.vue?vue&type=script&lang=js&"
export * from "./TourStages1x1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c327cc",
  null
  
)

export default component.exports